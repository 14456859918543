import React from "react"
import Layout from "../components/layouts/Layout.js"
import { graphql, useStaticQuery } from "gatsby"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "../components/GatsbyLink"
import Banner from "../components/Banner.js"
import * as styles from "../stylesheets/pages/privacy-policy.module.scss"

const PrivacyPolicy = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicPrivacyPolicy {
        data {
          banner_header {
            text
          }
          banner_image {
            url
            alt
          }
          banner_sub_header {
            text
          }
          header {
            text
          }
          text {
            richText
          }
          title {
            text
          }
        }
      }
    }
  `).prismicPrivacyPolicy.data

  return (
    <Layout>
      <Banner
        subHeader={data.banner_sub_header.text}
        alt={data.banner_image.alt}
      />
      <div className={styles.container}>
        <h2>{data.header.text}</h2>
        <div className={styles.description}>
          <RichText
            render={data.text.richText}
            serializeHyperlink={GatsbyLink}
          />
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
